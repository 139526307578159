<template>
  <div>
    <div
      class="modal fade"
      id="modal-form-cierre-individual"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cierre Manual Individual</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="row">
                <!-- Número Guía -->
                <div class="form-group col-md-3">
                  <label for="nro_guia">N° guia</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="nro_guia"
                    :disabled="accion == 1 || accion == 2"
                    @change="validarGuia"
                    placeholder="Numero guia"
                    v-model="form.nro_guia"
                    :class="$v.guia.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div v-if="accion == 0">
                    <div class="error" v-if="respuesta == 1">
                      Ingrese un numero de guia valido
                    </div>

                    <div class="error" v-if="respuesta == 2">
                      Guia sin cerrar
                    </div>
                    <div class="error" v-if="respuesta == 3">
                      Viaje sin finalizar
                    </div>
                    <div class="error" v-if="respuesta == 4">Guia cerrada</div>
                  </div>
                  <div v-if="accion == 1">
                    <p>
                      <span class="badge badge-warning"
                        >EL cierre supera la diferencia y necesita aprobacion
                      </span>
                    </p>
                  </div>
                  <div v-if="accion == 2">
                    <p>
                      <span class="badge badge-success"
                        >El cierre no excede la diferencia permitida
                      </span>
                    </p>
                  </div>
                </div>
                <!-- Vehículo -->
                <div class="form-group col-md-3">
                  <label for="vehiculo">Vehículo</label>
                  <v-select
                    :class="[
                      $v.vehiculo.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="vehiculo"
                    placeholder="Placa"
                    label="placa"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.vehiculos"
                    :filterable="true"
                    :disabled="accion == 1 || accion == 2"
                    @input="getSelectVehiculo()"
                  ></v-select>
                </div>
                <!-- Remolque -->
                <div class="form-group col-md-3">
                  <label for="remolque">Remolque</label>
                  <v-select
                    :class="[
                      $v.remolque.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="remolque"
                    placeholder="Placa"
                    label="placa"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.remolques"
                    :filterable="true"
                    :disabled="accion == 1 || accion == 2"
                    @input="getSelectRemolque()"
                  ></v-select>
                </div>
                <!-- Conductor -->
                <div class="form-group col-md-3">
                  <label for="conductor">Conductor</label>
                  <v-select
                    :class="[
                      $v.conductor.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="conductor"
                    placeholder="Número"
                    label="numero_documento"
                    class="form-control form-control-sm p-0 mr-3"
                    :options="listasForms.conductores"
                    :filterable="true"
                    :disabled="accion == 1 || accion == 2"
                    @input="getSelectConductor()"
                  ></v-select>
                </div>
                <!-- Fecha Descargue -->
                <div class="form-group col-md-3">
                  <label for="fecha_descargue">Fecha descargue</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    id="fecha_descargue"
                    placeholder="Fecha descargue"
                    :disabled="accion == 1 || accion == 2"
                    @change="checkFecha"
                    v-model="form.fecha_descargue"
                    :class="
                      $v.form.fecha_descargue.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <!-- Hora Descargue -->
                <div class="form-group col-md-3">
                  <label for="hora_descargue">Hora descargue</label>
                  <!-- <input
                    type="time"
                    class="form-control form-control-sm"
                    id="hora_descargue"
                    placeholder="Hora descargue"
                    :disabled="accion == 1 || accion == 2"
                    @change="checkFecha"
                    v-model="form.hora_descargue"
                    :class="
                      $v.form.hora_descargue.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  /> -->
                  <input
                    type="text"
                    id="hora_descargue"
                    class="form-control form-control-sm"
                    :disabled="accion == 1 || accion == 2"
                    @change="checkFecha"
                    v-model="form.hora_descargue"
                    :class="
                      $v.form.hora_descargue.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '24',
                    }"
                  />
                </div>
                <!-- Fecha Enturne -->
                <div class="form-group col-md-3">
                  <label for="fecha_enturne">Fecha enturne</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    id="fecha_enturne"
                    placeholder="Fecha enturne"
                    :disabled="accion == 1 || accion == 2"
                    @change="checkFecha"
                    v-model="form.fecha_enturne"
                    :class="
                      $v.form.fecha_enturne.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
                <!-- Hora Enturne -->
                <div class="form-group col-md-3">
                  <label for="hora_enturne">Hora enturne</label>
                  <!-- <input
                    type="time"
                    class="form-control form-control-sm"
                    id="hora_enturne"
                    placeholder="Hora enturne"
                    :disabled="accion == 1 || accion == 2"
                    @change="checkFecha"
                    v-model="form.hora_enturne"
                    :class="
                      $v.form.hora_enturne.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  /> -->
                  <input
                    type="text"
                    id="hora_enturne"
                    class="form-control form-control-sm"
                    :disabled="accion == 1 || accion == 2"
                    @change="checkFecha"
                    v-model="form.hora_enturne"
                    :class="
                      $v.form.hora_enturne.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '24',
                    }"
                  />
                </div>
                <!-- Gov -->
                <div class="form-group col-md-3">
                  <label for="gov">GOV</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="gov"
                    :disabled="accion == 1 || accion == 2"
                    placeholder="GOV"
                    @change="validarGov()"
                    v-model="form.gov"
                  />
                  <div class="error" v-if="form.diferencia_gov !== null">
                    <div
                      v-if="form.diferencia_gov > 2 || form.diferencia_gov < -2"
                    >
                      <span class="badge badge-warning">
                        {{ this.form.diferencia_gov }}
                      </span>
                    </div>
                    <div v-else>
                      <span class="badge badge-success">
                        {{ this.form.diferencia_gov }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- Gsv -->
                <div class="form-group col-md-3">
                  <label for="gsv">GSV</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="gsv"
                    placeholder="GSV"
                    @change="validarGsv()"
                    :disabled="accion == 1 || accion == 2"
                    v-model="form.gsv"
                    :class="$v.form.gsv.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div class="error" v-if="form.diferencia_gsv !== null">
                    <div
                      v-if="form.diferencia_gsv > 2 || form.diferencia_gsv < -2"
                    >
                      <span class="badge badge-warning">
                        {{ this.form.diferencia_gsv }}
                      </span>
                    </div>
                    <div v-else>
                      <span class="badge badge-success">
                        {{ this.form.diferencia_gsv }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- Nsv -->
                <div class="form-group col-md-3">
                  <label for="nsv">NSV</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="nsv"
                    :disabled="accion == 1 || accion == 2"
                    @change="validarNsv()"
                    placeholder="NSV"
                    v-model="form.nsv"
                    :class="$v.form.nsv.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div class="error" v-if="form.diferencia_nsv !== null">
                    <div
                      v-if="form.diferencia_nsv > 2 || form.diferencia_nsv < -2"
                    >
                      <span class="badge badge-warning">
                        {{ this.form.diferencia_nsv }}
                      </span>
                    </div>
                    <div v-else>
                      <span class="badge badge-success">
                        {{ this.form.diferencia_nsv }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- Bsv -->
                <div class="form-group col-md-3">
                  <label for="bsw">BSW</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="bsw"
                    :disabled="accion == 1 || accion == 2"
                    @change="validarBsw()"
                    placeholder="BSW"
                    v-model="form.bsw"
                    :class="$v.form.bsw.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div class="error" v-if="form.diferencia_bsw !== null">
                    <div
                      v-if="form.diferencia_bsw > 2 || form.diferencia_bsw < -2"
                    >
                      <span class="badge badge-warning">
                        {{ this.form.diferencia_bsw }}
                      </span>
                    </div>
                    <div v-else>
                      <span class="badge badge-success">
                        {{ this.form.diferencia_bsw }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- Api -->
                <div class="form-group col-md-3">
                  <label for="api">API</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="api"
                    :disabled="accion == 1 || accion == 2"
                    @change="validarApi()"
                    placeholder="API"
                    v-model="form.api"
                    :class="$v.form.api.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div class="error" v-if="form.diferencia_api !== null">
                    <div
                      v-if="form.diferencia_api > 2 || form.diferencia_api < -2"
                    >
                      <span class="badge badge-warning">
                        {{ this.form.diferencia_api }}
                      </span>
                    </div>
                    <div v-else>
                      <span class="badge badge-success">
                        {{ this.form.diferencia_api }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- Temperatura -->
                <div class="form-group col-md-3">
                  <label for="temperatura">Temperatura</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="temperatura"
                    :disabled="accion == 1 || accion == 2"
                    @change="validarTemp()"
                    placeholder="Temperatura"
                    v-model="form.temperatura"
                  />
                </div>
                <!-- Sal -->
                <div class="form-group col-md-3">
                  <label for="sal">% SAL</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="sal"
                    :disabled="accion == 1 || accion == 2"
                    placeholder="% SAL"
                    v-model="form.sal"
                  />
                </div>
                <!-- Azufre -->
                <div class="form-group col-md-3">
                  <label for="azufre">% Azufre</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="azufre"
                    :disabled="accion == 1 || accion == 2"
                    @change="validarAzufre()"
                    placeholder="% AZUFRE"
                    v-model="form.azufre"
                  />
                </div>
                <!-- Novedad 1 -->
                <div class="form-group col-md-6">
                  <label for="novedad_1">Novedad 1</label>
                  <select
                    id="novedad_1"
                    class="form-control form-control-sm"
                    :disabled="accion == 1 || accion == 2"
                    v-model="form.novedad_1"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="estado in listasForms.novedades"
                      :key="estado.id"
                      :value="estado.numeracion"
                    >
                      {{ estado.descripcion }}
                    </option>
                  </select>
                </div>
                <!-- Novedad 2 -->
                <div class="form-group col-md-6">
                  <label for="novedad_2">Novedad 2</label>
                  <select
                    id="novedad_2"
                    :disabled="accion == 1 || accion == 2"
                    class="form-control form-control-sm"
                    v-model="form.novedad_2"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="estado in listasForms.novedades"
                      :key="estado.id"
                      :value="estado.numeracion"
                    >
                      {{ estado.descripcion }}
                    </option>
                  </select>
                </div>
                <!-- Origen -->
                <div class="form-group col-md-6">
                  <label for="origen">Origen</label>
                  <v-select
                    :class="[
                      $v.origen.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="origen"
                    placeholder="Origen"
                    label="nombre"
                    :disabled="accion == 1 || accion == 2"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.sitios"
                    :filterable="true"
                    @input="getSelectOrigen()"
                  ></v-select>
                </div>
                <!-- Destino -->
                <div class="form-group col-md-6">
                  <label for="destino">Destino</label>
                  <v-select
                    :class="[
                      $v.destino.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="destino"
                    placeholder="Destino"
                    label="nombre"
                    :disabled="accion == 1 || accion == 2"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.sitios"
                    :filterable="true"
                    @input="getSelectDestino()"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
          <!-- Botones -->
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn bg-gradient-primary"
              v-show="
                !$v.form.$invalid &&
                  !$v.vehiculo.$invalid &&
                  !$v.remolque.$invalid &&
                  !$v.conductor.$invalid &&
                  !$v.origen.$invalid &&
                  !$v.destino.$invalid &&
                  !$v.guia.$invalid &&
                  accion == 0
              "
              @click="verificar()"
            >
              Verificar
            </button>
            <button
              type="button"
              class="btn bg-gradient-primary"
              v-show="
                !$v.form.$invalid &&
                  !$v.vehiculo.$invalid &&
                  !$v.remolque.$invalid &&
                  !$v.conductor.$invalid &&
                  !$v.origen.$invalid &&
                  !$v.destino.$invalid &&
                  !$v.guia.$invalid &&
                  (accion == 1 || accion == 2)
              "
              @click="cambiarDatos()"
            >
              Cambiar datos
            </button>

            <button
              type="button"
              class="btn bg-gradient-success"
              v-show="
                !$v.form.$invalid &&
                  !$v.vehiculo.$invalid &&
                  !$v.remolque.$invalid &&
                  !$v.conductor.$invalid &&
                  !$v.origen.$invalid &&
                  !$v.destino.$invalid &&
                  !$v.guia.$invalid &&
                  (accion == 1 || accion == 2)
              "
              @click="guardar()"
            >
              Guardar
            </button>
            <button type="button" class="btn bg-gradient-success" @click="limpiarForm()">
              Limpiar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, helpers } from "vuelidate/lib/validators";
import vSelect from "vue-select";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "CierreGuiaManual",
  components: {
    vSelect,
  },
  data() {
    return {
      cargando: false,
      guia: {},
      vehiculo: {},
      remolque: {},
      conductor: {},
      origen: {},
      destino: {},
      respuesta: null,
      form: {
        diferencia_gov: null,
        diferencia_gsv: null,
        diferencia_nsv: null,
        diferencia_api: null,
        diferencia_bsw: null,
      },
      accion: 0,
      listasForms: {
        novedades: [],
        vehiculos: [],
        conductores: [],
        remolques: [],
        sitios: [],
      },
    };
  },
  validations: {
    guia: {
      required,
    },
    vehiculo: {
      required,
    },
    remolque: {
      required,
    },
    conductor: {
      required,
    },
    origen: {
      required,
    },
    destino: {
      required,
    },
    form: {
      gsv: {
        required,
      },
      nsv: {
        required,
      },
      bsw: {
        required,
      },
      api: {
        required,
      },
      fecha_enturne: {
        required,
      },
      hora_enturne: {
        required,
        timeFormat,
      },
      fecha_descargue: {
        required,
      },
      hora_descargue: {
        required,
        timeFormat,
      },
    },
  },
  methods: {
    getNovedades() {
      axios.get("/api/lista/52").then((response) => {
        this.listasForms.novedades = response.data;
      });
    },
    validarTemp() {
      if (this.form.temperatura > 250 || this.form.temperatura < 50) {
        this.$swal({
          icon: "error",
          title: `La temperatura debe estar entre 50°C y 250°C. Favor validar la información!!`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.form.temperatura = null;
      }
    },
    validarApi() {
      if (this.form.api > 95 || this.form.api < 6) {
        this.$swal({
          icon: "error",
          title: `El A.P.I debe estar entre 6 y 95. Favor validar la información!!`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$swal({
          icon: "error",
          title: `El A.P.I debe estar entre 6 y 95. Favor validar la información!!`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.form.api = null;
      }
    },
    validarBsw() {
      if (this.form.bsw >= 1 || this.form.bsw < 0) {
        this.$swal({
          icon: "error",
          title: `El valor del porcentaje de B.S.W debe estar entre 0 y 1. Favor validar la información!!`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.form.bsw = null;
      }
    },
    validarSal() {
      if (this.form.sal > 20 || this.form.sal < 0) {
        this.$swal({
          icon: "error",
          title: `El valor del porcentaje de SAL debe estar entre 0 y 20. Favor validar la información!!`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.form.sal = null;
      }
    },
    validarAzufre() {
      if (this.form.azufre > 100 || this.form.azufre < 0) {
        this.$swal({
          icon: "error",
          title: `El valor del porcentaje de Azufre debe estar entre 0 y 100. Favor validar la información!!`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.form.azufre = null;
      }
    },
    validarGov() {
      if (this.form.gov != null) {
        if (this.form.gov > 300 || this.form.gov < 0) {
          this.$swal({
            icon: "error",
            title: `El valor del GOV no puede ser mayor a 300. Favor validar la información!!`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.form.gov = null;
        }

        if (this.form.gsv != null) {
          if (this.form.gsv > this.form.gov) {
            this.$swal({
              icon: "error",
              title: `El valor del GOV no puede ser menor al GSV. Favor validar la información!!`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.form.gov = null;
          }
        }
      }
    },
    validarGsv() {
      if (this.form.gsv >= this.form.gov) {
        this.$swal({
          icon: "error",
          title: `El valor de GSV no puede ser mayor o igual al valor de GOV. Favor validar la información!!`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.form.gsv = null;
      }
    },
    validarNsv() {
      if (this.form.nsv > this.form.gsv) {
        this.$swal({
          icon: "error",
          title: `El valor de NSV no puede ser mayor al valor de GSV. Favor validar la información!!`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.form.nsv = null;
      }
    },

    checkFecha() {
      if (this.form.fecha_enturne > this.form.fecha_descargue) {
        this.$swal({
          icon: "error",
          title: `La fecha enturne no puede ser mayor a la fecha de descargue...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.form.fecha_descargue = null;
        this.form.hora_descargue = null;
      } else {
        if (this.form.fecha_enturne == this.form.fecha_descargue) {
          if (this.form.hora_enturne > this.form.hora_descargue) {
            this.$swal({
              icon: "error",
              title: `La hora de enturne no puede ser mayor a la hora de descargue...`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.form.hora_descargue = null;
          }
        }
      }
    },
    validarGuia() {
      this.guia = null;
      this.respuesta = null;
      if (this.form.nro_guia.length == 12 || this.form.nro_guia.length == 13) {
        axios({
          method: "POST",
          url: "/api/hidrocarburos/cierreGuias/validarGuia",
          data: this.form,
        }).then((response) => {
          this.guia = response.data.datos;
          this.respuesta = response.data.respuesta;
        });
      } else {
        this.$swal({
          icon: "error",
          title: `El numero de guia debe contener 12 digitos!`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    getVehiculos() {
      axios.get("/api/admin/vehiculos/lista").then((response) => {
        this.listasForms.vehiculos = response.data;
      });
    },
    getRemolques() {
      axios.get("/api/admin/remolques/lista").then((response) => {
        this.listasForms.remolques = response.data;
      });
    },
    getConductores() {
      axios.get("/api/admin/conductores/lista").then((response) => {
        this.listasForms.conductores = response.data;
      });
    },
    getSitios() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },
    getSelectVehiculo() {
      this.form.vehiculo_id = null;
      if (this.vehiculo != null) {
        this.form.vehiculo_id = this.vehiculo.id;
      }
    },
    getSelectRemolque() {
      this.form.remolque_id = null;
      if (this.remolque != null) {
        this.form.remolque_id = this.remolque.id;
      }
    },
    getSelectConductor() {
      this.form.conductor_id = null;
      if (this.conductor != null) {
        this.form.conductor_id = this.conductor.id;
      }
    },
    getSelectOrigen() {
      this.form.origen_id = null;
      if (this.origen != null) {
        this.form.origen_id = this.origen.id;
      }
    },
    getSelectDestino() {
      this.form.destino_id = null;
      if (this.destino != null) {
        this.form.destino_id = this.destino.id;
      }
    },
    verificar() {
      let verificar = 0;
      if (this.form.vehiculo_id == this.guia.det_guias[0].vehiculo_id) {
        verificar++;
      }
      if (this.form.remolque_id == this.guia.det_guias[0].remolque_id) {
        verificar++;
      }
      if (this.form.conductor_id == this.guia.det_guias[0].conductor_id) {
        verificar++;
      }
      if (this.form.origen_id == this.guia.det_guias[0].sitio_origen_id) {
        verificar++;
      }
      if (this.form.destino_id == this.guia.det_guias[0].sitio_destino_id) {
        verificar++;
      }

      if (verificar == 5) {
        this.accion = 1;
        this.calcularDiferencia();
      } else {
        this.$swal({
          icon: "error",
          title: `Los datos de la guia no coinciden`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
    calcularDiferencia() {
      this.form.estado = 22;
      if (this.form.gov != null || this.form.gov != undefined) {
        this.form.diferencia_gov =
          100 - (this.form.gov / this.guia.det_guias[0].gov) * 100;
        if (this.form.diferencia_gov > 2 || this.form.diferencia_gov < -2) {
          this.form.estado = 21;
        }
      } else {
        this.form.diferencia_gov = null;
      }

      this.form.diferencia_gsv =
        100 - (this.form.gsv / this.guia.det_guias[0].gsv) * 100;
      this.form.diferencia_nsv =
        100 - (this.form.gsv / this.guia.det_guias[0].gsv) * 100;
      this.form.diferencia_bsw =
        100 - (this.form.bsw / this.guia.det_guias[0].bsw) * 100;
      this.form.diferencia_api =
        100 - (this.form.api / this.guia.det_guias[0].api) * 100;

      if (this.form.diferencia_gsv > 2 || this.form.diferencia_gsv < -2) {
        this.form.estado = 28;
      }

      if (this.form.diferencia_nsv > 2 || this.form.diferencia_nsv < -2) {
        this.form.estado = 27;
      }

      if (this.form.estado == 28) {
        this.accion = 2;
      }
    },
    guardar() {
      this.form.guia_id = this.guia.id;
      this.form.ventana_enturnamiento_id = this.guia.viaje.ventana_enturne_descargue_id;
      this.form.user_id = this.$store.getters.getUser.id;
      this.form.viaje_id = this.guia.viaje_id;
      if (this.accion == 1) {
        this.$swal({
          title: "Está seguro de realizar el cierre?",
          text: "EL cierre supera la diferencia y necesita aprobacion!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Terminar!",
        }).then((result) => {
          if (result.value) {
            this.accion = 3;
            axios({
              method: "POST",
              url: "/api/hidrocarburos/cierreGuias/cierreIndividual",
              data: this.form,
            })
              .then((response) => {
                this.limpiarForm();
                this.$refs.closeModal1.click();
                this.$swal({
                  icon: "success",
                  title: "Se guardo exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              })
              .catch((e) => {
                this.$swal({
                  icon: "error",
                  title: "Ocurrio un error, vualeva a intentarlo..." + e,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.accion = 1;
              });
          }
        });
      } else {
        this.accion = 3;
        axios({
          method: "POST",
          url: "/api/hidrocarburos/cierreGuias/cierreIndividual",
          data: this.form,
        })
          .then((response) => {
            this.limpiarForm();
            this.$refs.closeModal1.click();
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.accion = 2;
          });
      }
    },
    cambiarDatos() {
      this.accion = 0;
      this.respuesta = null;
      this.form.diferencia_gov = null;
      this.form.diferencia_gsv = null;
      this.form.diferencia_nsv = null;
      this.form.diferencia_api = null;
      this.form.diferencia_bsw = null;
      this.validarGuia();
    },

    limpiarForm() {
      this.accion = 0;
      this.guia = {};
      this.vehiculo = {};
      this.remolque = {};
      this.conductor = {};
      this.origen = {};
      this.destino = {};
      this.respuesta = null;
      this.form = {
        diferencia_gov: null,
        diferencia_gsv: null,
        diferencia_nsv: null,
        diferencia_api: null,
        diferencia_bsw: null,
      };
    },
  },
  mounted() {
    this.getNovedades();
    this.getVehiculos();
    this.getRemolques();
    this.getConductores();
    this.getSitios();
  },
};
</script>
